<template>
  <!-- end:: Header -->
  <div>
    <h3>Business Directory Subscription Plan</h3>
    <div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
      id="kt_content">

      <!-- begin:: Content -->
      <!-- begin:: hero -->
      <div class="kt-sc-license" style="background-image: url('/assets/media/bg/bg-9.jpg')">
        <div class="kt-container">
          <div class="kt-sc__top">
            <h3 class="kt-sc__title">Select a Plan</h3>
            <div class="kt-sc__nav">
              <a href="#" class="kt-link kt-link--light kt-font-bold">Help Center</a>
            </div>
          </div>
        </div>
      </div>

      <!-- end:: hero -->
      <div class="kt-negative-spacing--7"></div>

      <!-- begin:: infobox -->
      <div class="kt-grid__item">
        <div class="kt-container">
          <div class="kt-portlet">
            <div class="kt-portlet__body">
              <div class="kt-infobox">
                <div class="kt-infobox__body">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="kt-infobox__section">
                        <div class="kt-infobox__content">
                          <h2 class="kt-infobox__subtitle">Organisation Subscription Plan</h2>
                        </div>
                      </div>
                      <!-- <div class="kt-infobox__section">
                        <div class="kt-infobox__content kt-infobox__content--md">
                          <p>
                            The point here is that anyone can ramble on and on or even write brief statement about something that really
                            <a href="javascript:;">Download Sample</a>. If you want people to continue to follow you and your blog Your blog post fonts are automatically styled according to your site's theme. However, you can change the text font and format by selecting the text and clicking the options.
                          </p>
                        </div>
                      </div>-->
                      <div class="kt-infobox__section">
                        <div class="kt-infobox__content">
                          <div class="table-responsive">
                            <table class="table table-light table-light--info">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th  class="table-center">Free</th>
                                  <th class="table-center">Professional</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Free Listing</td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Multiple Branch Location</td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Services Display</td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Add Keywords</td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Business Logo</td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Opening Hours</td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Social Media Links</td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">External Links</td>
                                  <td  class="table-center"> <i class="flaticon2-cross text-danger"></i></td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">FAQ</td>
                                  <td  class="table-center"> <i class="flaticon2-cross text-danger"></i></td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Gallery & Featured Video</td>
                                  <td  class="table-center"> <i class="flaticon2-cross text-danger"></i></td>
                                  <td  class="table-center"> <i class="flaticon2-check-mark text-success"></i></td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Email Support</td>
                                  <td  class="table-center">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td  class="table-center">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Phone Support</td>
                                  <td  class="table-center">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td  class="table-center">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>

                                <tr v-if="!organisation.active_cart">
                                  <td class="kt-font-bold table-row-title"></td>
                                  <td class="kt-font-bold table-row-title text-center" >

                                    <v-btn
                                      @click="buildCartObject(subscriptions[0])"
                                      color="primary"
                                      class="btn-save-popup"
                                    > <i class="fa fa-cart-plus"></i> Free</v-btn>
                                  </td>
                                  <td class="kt-font-bold table-row-title text-center" v-if="size>=1">
                                    <v-btn v-if="!this.subscription.id && this.subscription.id !=subscriptions[1].id"
                                      @click="buildCartObject(subscriptions[1], 'new')"
                                      color="primary"
                                      class="btn-save-popup"
                                    ><i class="fa fa-cart-plus"></i>{{subscriptions[1].currency}}{{subscriptions[1].price}} /year </v-btn>
                                     <v-btn v-else
                                      @click="buildCartObject(subscriptions[1], 'renew')"
                                      color="primary"
                                      class="btn-save-popup"
                                    ><i class="fa fa-cart-plus"></i>RENEW {{subscriptions[1].currency}}{{subscriptions[1].price}} /year </v-btn>
                                    <!-- <v-btn
                                      v-if="size==1"
                                      @click="buildCartObject(subscriptions[0])"
                                      class="btn btn-info"
                                    >Add To Cart [{{subscriptions[0].currency}}{{subscriptions[0].price}}]/Month</v-btn> -->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="kt-infobox__section">
                        <div class="kt-infobox__content kt-infobox__content--md">
                          <p class="text-info" v-if="organisation.active_cart">
                            <small>
                              *Subscription Plan has been added to your cart. Please check your cart item to cotinue.<br/>
                              <router-link :to="{name: 'cart'}"  color="primary"
                                      class="btn btn-info btn-save-popup">Continue to Checkout</router-link>
                            </small>
                          </p>
                          <p>Note: Price are exclusive of government service tax.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- end:: infobox -->

      <!-- end:: iconbox -->

      <!-- end:: Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import SubscriptionService from "@/services/subscription/SubscriptionService";
import OrganisationService from "@/services/organisation/OrganisationService";

const subscriptionService = new SubscriptionService();
const organsationService = new OrganisationService();

export default {
  name: "support",
  data() {
    return {
      data: {},
      slug: null,
      subscriptions: [],
      size: null,
      organisation: {},
      cart: {},
      subscription:{}
    };
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ivoice", route: "dashboard" }
    ]);
    this.slug = this.$route.params.slug;
    this.getOrganisation();
    this.getDirectoryMembership();
  },
  methods: {
    ...mapActions(["ADD_TO_CART"]),
    getOrganisation() {
      organsationService
        .show(this.slug)
        .then(response => {
          this.organisation = response.data.organisation;
          this.subscription = this.organisation.subscription;
        })
        .catch(error => {     

        });
    },
    getDirectoryMembership() {
      subscriptionService
        .getDirectorySubscription(this.slug)
        .then(response => {
          this.subscriptions = response.data.plan;
          this.size = response.data.size;
        })
        .catch(error => {

        });
    },
    buildCartObject(item, purchase_type) {
      this.cart = {
        item_type: "directory",
        purchase_type: purchase_type,
        terms: "12",
        organisation_id: this.organisation.id,
        plan_id: item.id,
        item_price: item.price,
        is_processed: 0
      };

      this.addToCart();
    },
    addToCart() {
      this.ADD_TO_CART(this.cart)
        .then(response => {
          setTimeout(() => {
            this.$snotify.success("Added to cart");
             this.$router.push({name:"cart"});
          }, 2000);
        })
        .catch(error => {
          this.$snotify.error("Sorry we were not able to add this plan to cart. Please try again later.");
        });
    }
  }
};
</script>
