import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class SubscriptionService {

      getDirectorySubscription(slug) {
            let url = API_URL + 'user/organisation/'+slug+'/subscription';
            return apiService.get(url);
      }

      getWebsiteSubscription(website) {
            let url = API_URL + 'user/website/'+website+'/subscription';
            return apiService.get(url);
      }
}
